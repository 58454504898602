<template src="./Ovas.html"></template>

<script>
import VueGl from "../../components/VueGl/VueGl";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "ovas",
  components: {
    VueGl,
    ArrobaMedellinArrobitaNoData,
  },
  data() {
    return {
      role: "",
      userId: "",
      dataStudent: "",
      gameId: "",
      gameType: "",
    };
  },
  destroyed() {
      location.reload();
  },
  mounted() {
    this.role = this.$store.state.User.user.role.name;
    this.userId = this.$store.state.User.user.id;
    this.gameId = this.$route.params.gameId;
    this.gameType = this.$route.params.gameType;
  },
  methods: {
    sendText() {
      const infoStudent = {
        token: this.$store.state.User.tokenControllerPhp,
        tokenNode: this.$store.state.User.jwt,
        userId: this.userId,
        gameId: this.gameId,
        gameType: this.gameType,
      };
      this.dataStudent = JSON.stringify(infoStudent);
      console.log("el estudiante", this.dataStudent);
      this.$refs.vuegl.message("Bridge", "SendToUnity", this.dataStudent);
    },
    fullscreen() {
      this.$refs.vuegl.fullscreen();
    },
  },
  computed: {
    roleValidation() {
      return ["Student"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./Ovas.scss"></style>