<template src="./VueGl.html"></template>

<script>
/* eslint-disable */
export default {
  props: [
    "loaderUrl",
    "dataUrl",
    "frameworkUrl",
    "codeUrl",
    "width",
    "height",
    "hideFooter",
  ],
  name: "vueGl",
  data() {
    return {
      containerId:
        "unity-container-" +
        Number(
          Math.random().toString().substr(3, length) + Date.now()
        ).toString(36),
      gameInstance: null,
      loaded: false,
      progress: 0,
      error: null,
    };
  },
  methods: {
    fullscreen() {
      this.gameInstance.SetFullscreen(1);
    },
    message(gameObject, method, param) {
      if (param === null) {
        param = "";
      }
      if (this.gameInstance !== null) {
        this.gameInstance.SendMessage(gameObject, method, param);
      } else {
        console.warn(
          "vue-unity-webgl: you've sent a message to the Unity content, but it wasn\t instantiated yet."
        );
      }
    },
  },
  mounted() {
    this.$store.dispatch("fetchLoading", true);
    let container = this.$refs["unity-container"];
    let canvas = this.$refs.canvas;
    let loadingBar = this.$refs["unity-loading-bar"];
    let progressBarFull = this.$refs["unity-progress-bar-full"];
    let fullscreenButton = this.$refs["unity-fullscreen-button"];
    let warningBanner = this.$refs["unity-warning"];

    // Shows a temporary message banner/ribbon for a few seconds, or
    // a permanent error message on top of the canvas if type=='error'.
    // If type=='warning', a yellow highlight color is used.
    // Modify or remove this function to customize the visually presented
    // way that non-critical warnings and error messages are presented to the
    // user.
    function unityShowBanner(msg, type) {
      function updateBannerVisibility() {
        warningBanner.style.display = warningBanner.children.length
          ? "block"
          : "none";
      }
      var div = document.createElement("div");
      div.innerHTML = msg;
      warningBanner.appendChild(div);
      if (type == "error") div.style = "background: red; padding: 10px;";
      else {
        if (type == "warning") div.style = "background: yellow; padding: 10px;";
        setTimeout(function () {
          warningBanner.removeChild(div);
          updateBannerVisibility();
        }, 5000);
      }
      updateBannerVisibility();
    }
    var loaderUrl = this.loaderUrl;
    var config = {
      dataUrl: this.dataUrl,
      frameworkUrl: this.frameworkUrl,
      codeUrl: this.codeUrl,
      streamingAssetsUrl: "StreamingAssets",
      companyName: "DefaultCompany",
      productName: this.productName,
      productVersion: "1.0",
      showBanner: unityShowBanner,
    };

    // By default Unity keeps WebGL canvas render target size matched with
    // the DOM size of the canvas element (scaled by window.devicePixelRatio)
    // Set this to false if you want to decouple this synchronization from
    // happening inside the engine, and you would instead like to size up
    // the canvas DOM size and WebGL render target sizes yourself.
    // config.matchWebGLToCanvasSize = false;

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      // Mobile device style: fill the whole browser client area with the game canvas:

      var meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content =
        "width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes";
      document.getElementsByTagName("head")[0].appendChild(meta);
      container.className = "unity-mobile";

      // To lower canvas resolution on mobile devices to gain some
      // performance, uncomment the following line:
      // config.devicePixelRatio = 1;

      canvas.style.width = window.innerWidth + "px";
      canvas.style.height = window.innerHeight + "px";

      unityShowBanner("WebGL builds are not supported on mobile devices.");
    } else {
      // Desktop style: Render the game canvas in a window that can be maximized to fullscreen:
      console.log(canvas);
      canvas.style.width = this.width;
      canvas.style.height = this.height;
    }

    loadingBar.style.display = "block";

    var script = document.createElement("script");
    script.src = loaderUrl;
    script.onload = () => {
      createUnityInstance(canvas, config, (progress) => {
        progressBarFull.style.width = 100 * progress + "%";
        if (progress === 1) {
          this.$store.dispatch("fetchLoading", false);
        }
      })
        .then((unityInstance) => {
          this.gameInstance = unityInstance;
          loadingBar.style.display = "none";
        })
        .catch((message) => {
          alert(message);
        });
    };
    document.body.appendChild(script);
  },
};
</script>

<style lang="scss" scoped src="./VueGl.scss"></style>